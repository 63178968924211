// @flow
import _ from 'lodash';
import * as React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import { useRemoteLoadableState, useQuery } from '../../utils/hooks';
import { SURVEY_API_ROOT } from '../../utils/rest';
import { ResultHeader, ResultBody, ResultFooter } from '../../components/result';
import resultImages from '../../resources/images/resultImages';
import commonImages from '../../resources/images/commonImages';
import surveys from '../../resources/surveys';
import { connect } from 'react-redux';
import { surveySessionActions } from '../../ducks/survey';
import shareImages from '../../resources/images/shareImages';

const Container = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  padding-bottom: 120px;
`;

const DownloadContainer = styled.div`
  margin-top: 64px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 160px;
  }
`;

const DownloadImg = styled.img`
  width: 100%;
  height: auto;
  display: ${props => props.hidden === 'xs' ? 'none' : 'inline'};
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: ${props => props.hidden === 'md' ? 'none' : 'inline'};
  }
`;

const DownloadKlarContainer = styled.div`
  width: 100%;
  padding: 0 48px;
  position: absolute;
  top: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    padding: 0;
    width: 50%;
    right: 0px;
    padding-left: 12%;
    position: absolute;
    top: 25%;
    align-items: flex-start;
  }
`;

const DownloadKlarLogo = styled.img`
  width: 96px;
  height: 24px;
  object-fit: contain;
  display: ${props => props.hidden === 'xs' ? 'none' : 'inline'};
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    width: 96px;
    height: 24px;
    display: ${props => props.hidden === 'md' ? 'none' : 'inline'};
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: 128px;
    height: 32px;
    display: ${props => props.hidden === 'md' ? 'none' : 'inline'};
  }
`;

const DownloadKlarHeading = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  opacity: 0.75;
  text-align: center;
  color: #fff;
  white-space: pre-line;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    text-align: left;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 20px;
    line-height: 1.6;
    text-align: left;
  }
`;

const DownloadButtonContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    justify-content: flex-start;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    justify-content: flex-start;
  }
`;

const DownloadButton = styled(ReactGA.OutboundLink)`
  height: 40px;
  flex-grow: 0;
  &:not(:first-child) {
    margin-left: 12px;
  }
  > img {
    height: 100%;
    object-fit: contain;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 40px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    height: 56px;
  }
`;

const TalkToExpertsLabel = styled.span`
  width: 210px;
  word-break: keep-all;
  opacity: 0.5;
  font-size: 12px;
  line-height: 2;
  margin-top: 64px;
  color: #fff;
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 1;
    margin-top: 144px;
    width: 440px;
  }
`;

const CitationLabel = styled.span`
  width: calc(100% - 76px);
  margin-top: 32px;
  font-size: 12px;
  opacity: 0.5;
  word-break: keep-all;
  color: #fff;
  line-height: 2;
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 2;
    width: 622px;
  }
`;

const ResultComponent = (props) => {
  const { sessionId } = useParams();
  const queryParams = useQuery();
  const resultType = queryParams.get('sharedResult');
  const resultPercent = queryParams.get('percent');
  const location = useLocation();
  const [anlzResult, loading, refetchResult] = useRemoteLoadableState(
    `${SURVEY_API_ROOT}/klar/web/marketing/survey/session/${sessionId}/anlz-result`,
    {},
    true,
    {}
  );
  React.useEffect(() => { props.submitAnswers() }, []);
  const surveyName = (_.split(resultType, '-')[0]) || _.lowerCase(anlzResult.pubSurveyName);
  const resultToShare = resultType || `${surveyName}-${anlzResult.anlzResult && anlzResult.anlzResult.actualLevel.value}`;
  const level = +_.split(resultToShare, '-')[1];
  const surveyLocalModel = _.filter(surveys, (model) => model.key === surveyName)[0];
  const resultPercentToShare = resultPercent || (anlzResult.anlzResult && anlzResult.anlzResult.percent);
  return (
    <Container>
      <Helmet>
        <meta property="og:url" content={`${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${resultPercent}`}/>
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="2434875116760316" />
        <meta property="og:title" content={surveyLocalModel && surveyLocalModel.resultTitles[level - 1]}/>
        <meta property="og:description" content={`내 ${surveyLocalModel && surveyLocalModel.assessing}는 ${resultPercentToShare}%입니다.`}/>
        <meta property="og:image" content={shareImages[`${_.join(_.split(resultToShare, '-'), '')}2x`]}/>
        <meta property="og:image:width" content='1260'/>
        <meta property="og:image:height" content='1260'/>
      </Helmet>
      <ResultHeader anlzResult={anlzResult} />
      <ResultBody anlzResult={anlzResult} />
      <ResultFooter level={level} surveyLocalModel={surveyLocalModel} anlzResult={anlzResult} resultToShare={resultToShare} percent={resultPercentToShare} />
      <DownloadContainer>
        <DownloadImg src={resultImages.downloadSm} hidden='md' srcSet={`${resultImages.downloadSm2x} 2x, ${resultImages.downloadSm3x} 3x`} alt='download klar sm'/>
        <DownloadImg src={resultImages.downloadLg} hidden='xs' srcSet={`${resultImages.downloadLg2x} 2x`} alt='download klar lg'/>
        <DownloadKlarContainer>
          <DownloadKlarLogo src={commonImages.logoMD} hidden='md' alt='logo xs'/>
          <DownloadKlarLogo src={commonImages.logoLG} hidden='xs' alt='logo md'/>
          <DownloadKlarHeading>
            {'클라 앱을 다운받고 무료로\n클라의 다양한 콘텐츠를 이용해보세요.'}
          </DownloadKlarHeading>
          <DownloadButtonContainer>
            <DownloadButton
              to='https://apps.apple.com/kr/app/id1492006887'
              eventLabel='Download iOS'
              target='_blank'
            >
              <img src={commonImages.appStore} srcSet={`${commonImages.appStore2x} 2x, ${commonImages.appStore3x} 3x`} alt='appstore'/>
            </DownloadButton>
            <DownloadButton 
              to='https://play.google.com/store/apps/details?id=com.ybrain.klar'
              eventLabel='Download Android'
              target='_blank'
            >
              <img src={commonImages.googlePlay} srcSet={`${commonImages.googlePlay2x} 2x, ${commonImages.googlePlay3x} 3x`} alt='google play'/>
            </DownloadButton>
          </DownloadButtonContainer>
        </DownloadKlarContainer>
      </DownloadContainer>
      <TalkToExpertsLabel>본 테스트는 자가진단용이며 정확한 진단은 전문가와 상담하시기 바랍니다.</TalkToExpertsLabel>
      <CitationLabel>{surveyLocalModel && surveyLocalModel.citation}</CitationLabel>
    </Container>
  );
}

export const Result = connect(null, {
  submitAnswers: surveySessionActions.submitAnswers
})(ResultComponent);