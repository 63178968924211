// @flow
import _ from 'lodash';
import * as React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function useRemoteLoadableState(url: string, params?: any, fetch?: boolean = true, defaultLoadable?: any = []) {
  const [loadable, setLoadable] = React.useState(defaultLoadable);
  const [loading, setLoading] = React.useState(false);
  // trigger initial load
  React.useEffect(() => {
    async function fetchState() {
      try {
        setLoading(true);
        const response = await axios.get(url, {
          params
        });
        setLoadable(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    const doFetch = _.isNil(fetch) || fetch;
    if (doFetch) {
      fetchState();
    } else {
      setLoadable(defaultLoadable);
    }
  }, _.concat([url, fetch], _.map(params, param => param)));
  async function refetchState() {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        params
      });
      setLoadable(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }
  return [loadable, loading, refetchState];
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export { useRemoteLoadableState, useQuery };