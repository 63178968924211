// @flow
import styled from 'styled-components';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CommentLabel = styled.span`
  width: 100%;
  word-break: keep-all;
  text-align: center;
  color: #fff;
  opacity: 0.4;
  font-size: 14px;
  line-height: 1.5;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 20px;
    line-height: 1;
  }
`;

export const PromptLabel = styled.span`
  width: 100%;
  word-break: keep-all;
  text-align: center;
  color: #fff;
  font-size: 28px;
  line-height: 1.57;
  margin-top: 16px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 24px;
    font-size: 48px;
    font-weight: 300;
    line-height: normal;
  }
`;

export const AnswerChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  width: 100%;
  height: 40px;
  > *:not(:first-child) {
    margin-left: 12px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: 1048px;
    margin-top: 80px;
    height: 80px;
    > *:not(:first-child) {
      margin-left: 32px;
    }
  }
`;

export const AnswerRangeLabel = styled.span`
  opacity: 0.4;
  color: #fff;
  line-height: 1;
  font-size: 12px;
  min-width: 23px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const ChoiceButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export const ChoiceButton = styled.div`
  width: 40px;
  height: 40px;
  opacity: ${props => !props.selected ? 0.25 : 1};
  border: ${props => !props.selected ? 'solid 2px #fff' : 'none'};
  background-color: ${props => !props.selected ? 'transparent' : props.theme.colors[props.surveyKey]};
  border-radius: 20px;
  cursor: pointer;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    width: 80px;
    height: 80px;
    border: ${props => !props.selected ? 'solid 4px #fff' : 'none'};
    border-radius: 40px;
    &:hover {
      opacity: 1;
      border-color: ${props => props.theme.colors[props.surveyKey]};
    }
  }
`;