// @flow
import * as React from 'react';
import ReactGA, { OutboundLink } from 'react-ga';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Home } from './home';
import commonImages from '../resources/images/commonImages';
import { Intro, Take } from './survey';
import { Result } from './result';

const Container = styled.div`
  width: 100%;
  min-height: 500px;
  background-color: ${props => props.theme.colors.klarDeep};
  display: flex;
  flex-direction: column;
  position: relative;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    background-image: url(${commonImages.mainBg1x});
    background-size: cover;
    background-position: center top;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) and (-o-min-device-pixel-ratio: 5/4),
  only screen and (min-width: ${props => props.theme.breakpoints.md}px) and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-width: ${props => props.theme.breakpoints.md}px) and (min--moz-device-pixel-ratio: 1.25),
  only screen and (min-width: ${props => props.theme.breakpoints.md}px) and (min-device-pixel-ratio: 1.25),
  only screen and (min-width: ${props => props.theme.breakpoints.md}px) and (min-resolution: 1.25dppx) {
    background-image: url(${commonImages.mainBg2x});
    background-size: cover;
    background-position: center top;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DownloadButton = styled(OutboundLink)`
  margin-right: 24px;
  font-size: 12px;
  color: #fff;
  height: 32px;
  padding: 0 14px;
  display: ${props => props.hidden === 'xs' ? 'none' : 'flex'};
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0,0%,100%,.5);
  border-radius: 5px;
  flex-grow: 0;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    height: 40px;
    padding: 0;
    border: none;
    display: ${props => props.hidden === 'lg' ? 'none' : 'flex'};
    > img {
      height: 100%;
      width: auto;
    }
  }
`;

const LogoButton = styled.a`
  margin: 24px;
  display: block;
  width: 64px;
  height: 16px;
  background-image: url(${commonImages.logo});
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin: 30px 40px;
    width: 96px;
    height: 24px;
    background-image: url(${commonImages.logoMD});
  }
`;

function checkMobile(userAgent){
  if (!userAgent) {
    return 'other';
  }
  if (userAgent.indexOf('android') > -1) {
      //안드로이드
      return "android";
  } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1) {
      //IOS
      return "ios";
  } else {
      //아이폰, 안드로이드 외
      return "other";
  }
  
}


export const Screens = ({ userAgent }) => {
  const mobilePlatform = checkMobile(userAgent.toLowerCase())
  return (
    <Container>
      <TopContainer>
        <LogoButton href='https://www.everydayklar.com/' />
        <DownloadContainer>
          <DownloadButton
            to={
              mobilePlatform === 'ios' ?
              'https://apps.apple.com/kr/app/id1492006887' :
              'https://play.google.com/store/apps/details?id=com.ybrain.klar'
            }
            eventLabel='Download Mobile'
            target='_blank'
            hidden='lg'
          >
            앱 다운로드
          </DownloadButton>
          <DownloadButton
            to={'https://apps.apple.com/kr/app/id1492006887'}
            eventLabel='Download iOS'
            target='_blank'
            hidden='xs'
          >
            <img src={commonImages.appStore} srcSet={`${commonImages.appStore2x} 2x, ${commonImages.appStore3x} 3x`} alt='appstore'/>
          </DownloadButton>
          <DownloadButton
            to={'https://play.google.com/store/apps/details?id=com.ybrain.klar'}
            eventLabel='Download Android'
            target='_blank'
            hidden='xs'
          >
            <img src={commonImages.googlePlay} srcSet={`${commonImages.googlePlay2x} 2x, ${commonImages.googlePlay3x} 3x`} alt='google play'/>
          </DownloadButton>
        </DownloadContainer>
      </TopContainer>
      <Switch>
        <Route exact path='/' render={(props) => {
          ReactGA.pageview(props.location.pathname + props.location.search);
          return <Home/>;
        }}/>
        <Route exact path='/:surveyName(stress|insomnia|depression|anxiety|anger|adhd)' render={(props) => {
          ReactGA.pageview(props.location.pathname + props.location.search);
          return <Intro/>;
        }}/>
        <Route exact path='/:surveyName(stress|insomnia|depression|anxiety|anger|adhd)/take' render={(props) => {
          return <Redirect to={`/${props.match.params.surveyName}/take/1`}/>;
        }}/>
        <Route path='/:surveyName(stress|insomnia|depression|anxiety|anger|adhd)/take/:pageNum' render={(props) => {
          ReactGA.pageview(props.location.pathname + props.location.search);
          return <Take/>;
        }}/>
        <Route exact path='/result/:sessionId' render={(props) => {
          ReactGA.pageview(props.location.pathname + props.location.search);
          return <Result/>;
        }}/>
        <Route render={(props) => {
          return <Redirect to='/'/>
        }}/>
      </Switch>
    </Container>
  );
}
