// @flow
// module imports
import { combineReducers } from 'redux';
import surveySessionReducer, { actionCreators as surveySessionActions } from './SurveySession';

const reducer = combineReducers({
  session: surveySessionReducer
});

export default reducer;

export {
  surveySessionActions
};
