// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  SurveyCardImg,
  SurveyCardContainer,
  SurveyHeading,
  SurveyHeadingContainer,
  SurveyTitle
} from './styled';
import homeImages from '../../../resources/images/homeImages';
import { Grid } from '../../common';
import { Link } from 'react-router-dom';
import surveys from '../../../resources/surveys';

export const SurveyList = () => {
  return (
    <Grid
      container
      maxWidths={{ xs: 375, md: 1148 }}
      paddings={{ xs: 24, md: 56 }}
      verticalSpacings={{ xs: 24, md: 32, xl: 72 }}
      horizontalSpacings={{ xs: 16, md: 24, xl: 56 }}
    >
      {_.map(surveys, survey => (
        <Grid item xs={6} md={4} key={survey.key}>
          <Link to={`/${survey.key}`}>
            <SurveyCardContainer>
              <SurveyCardImg src={homeImages[`${survey.key}Bg`]} />
              <SurveyHeadingContainer>
                <SurveyHeading>{survey.heading}</SurveyHeading>
                <SurveyTitle>{survey.title}</SurveyTitle>
              </SurveyHeadingContainer>
            </SurveyCardContainer>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}