// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 48px;
  position: relative;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    padding: 76px 0 96px;
  }
`;

export const SurveyName = styled.span`
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: 0.5;
  font-family: 'GothamRounded';
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 32px;
    font-weight: bold;
  }
`;

export const SurveyDescMain = styled.span`
  text-align: center;
  word-break: keep-all;
  margin-top: 12px;
  font-size: 28px;
  line-height: 1.38;
  color: #fff;
  max-width: 300px;
  > span {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 8px;
      opacity: ${props => props.surveyKey === 'sleep' ? 0.75 : 0.5};
      background-color: ${props => props.theme.colors[props.surveyKey]};
      z-index: -1;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 48px;
    max-width: 95%;
    margin-top: 32px;
    line-height: normal;
    > span::before {
      height: 16px;
    }
  }
`;

export const SurveyDescSub = styled.span`
  color: #fff;
  font-size: 16px;
  opacity: 0.5;
  line-height: 1.5;
  margin-top: 8px;
  word-break: keep-all;
  text-align: center;
  max-width: 95%;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 24px;
    line-height: 1;
    margin-top: 16px;
  }
`;

const BaseLink = ({ surveyKey, ...props }) => (
  <Link {...props} className={props.className}>{props.children}</Link>
)

export const StartButton = styled(BaseLink)`
  margin-top: 24px;
  border-radius: 24px;
  width: 240px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  color: #fff;
  background-color: ${props => props.theme.colors[props.surveyKey]};
  cursor: pointer;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 48px;
    border-radius: 32px;
    width: 436px;
    height: 64px;
    font-size: 24px;
    line-height: 64px;
  }
`;