// @flow
import _ from 'lodash';

const START_SURVEY: 'klarsurveyw/survey/take/START_SURVEY' = 'klarsurveyw/survey/take/START_SURVEY';
const NEXT_PAGE: 'klarsurveyw/survey/take/NEXT_PAGE' = 'klarsurveyw/survey/take/NEXT_PAGE';
const PREV_PAGE: 'klarsurveyw/survey/take/PREV_PAGE' = 'klarsurveyw/survey/take/PREV_PAGE';
const SELECT_ANSWER_CHOICE: 'klarsurveyw/survey/take/SELECT_ANSWER_CHOICE' = 'klarsurveyw/survey/take/SELECT_ANSWER_CHOICE';
const SUBMIT_ANSWERS: 'klarsurveyw/survey/take/SUBMIT_ANSWERS' = 'klarsurveyw/survey/take/SUBMIT_ANSWERS';

type StartSurveyAction = {
  type: typeof START_SURVEY,
  payload: {
    survey: any
  }
};

// prob won't need page change actions if handled by location pathname
type NextPageAction = {
  type: typeof NEXT_PAGE
};

type PrevPageAction = {
  type: typeof PREV_PAGE
};

type SelectAnswerChoiceAction = {
  type: typeof SELECT_ANSWER_CHOICE,
  payload: {
    qIdx: number,
    choice: number,
    score: number,
    responseTime: number,
  }
};

// may not need this. just do it in component
type SubmitAnswersAction = {
  type: typeof SUBMIT_ANSWERS
};

type TakeSurveyActions = (
  StartSurveyAction |
  SelectAnswerChoiceAction |
  SubmitAnswersAction
);

const INITIAL_STATE = {
  survey: null,
  answers: [],
};

export default function reducer(state = INITIAL_STATE, action: TakeSurveyActions) {
  switch (action.type) {
    case START_SURVEY:
      return {
        ...state,
        survey: action.payload.survey,
        answers: []
      };
    case SELECT_ANSWER_CHOICE:
      const question = state.survey.questions[action.payload.qIdx];
      const answer = {
        analytics: {
          responseTime: action.payload.responseTime
        },
        type: 'RATING',
        tag: question.tag,
        answer: action.payload.choice,
        score: action.payload.score,
        exceptScored: false,
        questionId: question.id,

      }
      if (state.answers.length === action.payload.qIdx) {
        return {
          ...state,
          answers: _.concat(state.answers, [answer])
        };
      } else if (state.answers.length > action.payload.qIdx) {
        return {
          ...state,
          answers: _.map(state.answers, (origAnswer, idx) => {
            if (action.payload.qIdx === idx) {
              return answer;
            }
            return origAnswer;
          })
        };
      }
      return state;
    case SUBMIT_ANSWERS:
      return INITIAL_STATE;
    default: return state;
  }
}

const startSurvey = (survey: any) => {
  return {
    type: START_SURVEY,
    payload: {
      survey
    }
  };
};

const selectAnswerChoice = (qIdx: number, choice: number, score: number, responseTime: number) => {
  return {
    type: SELECT_ANSWER_CHOICE,
    payload: {
      qIdx,
      choice,
      score,
      responseTime,
    }
  };
};

const submitAnswers = () => {
  return {
    type: SUBMIT_ANSWERS
  };
};

export const actionCreators = {
  startSurvey,
  selectAnswerChoice,
  submitAnswers
};