// @flow
import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: calc(100% - 48px);
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: 379px;
    margin-top: 72px;
  }
`;

export const ShareResultHeadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 16px;
  align-items: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: 379px;
    height: 40px;
  }
`;

export const ShareResultDivider = styled.div`
  flex: 1 1 0%;
  height: 1px;
  background-color: #fff;
  opacity: 0.12;
`;

export const ShareResultHeading = styled.span`
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
  line-height: 1.33;
  margin: 0 16px;
  display: ${props => props.hidden === 'xs' ? 'none' : 'inline'};
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 16px;
    line-height: 2.5;
    display: ${props => props.hidden === 'lg' ? 'none' : 'inline'};
  }
`;

export const ShareResultButtonContainer = styled.div`
  margin-top: 24px;
  width: 252px;
  height: 48px;
  display: flex;
  justify-content: space-between;
`;

export const ShareButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  flex-grow: 0;
  cursor: pointer;
  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
  &:focus {
    outline: none;
  }
`;

export const LinkAddress = styled.input`
  position: absolute;
  top: -100;
  opacity: 0;
`;

export const OtherTestsButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  opacity: 0.5;
  margin-top: 40px;
  cursor: pointer;
  > *:not(:first-child) {
    margin-left: 5px;
  }
  > span {
    font-size: 14px;
    line-height: 1.29;
    color: #fff;
    text-decoration: underline;
  }
  > img {
    width: 6px;
    height: 9px;
    object-fit: contain;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    > span {
      font-size: 16px;
      line-height: 1;
      text-decoration: none;
    }
    &:hover {
      opacity: 1;
    }
  }
`;