// @flow
import * as React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => props.maxWidths.xs ? `${props.maxWidths.xs}px` : '100%'};
  padding: ${props => (props.paddings && props.horizontalSpacings) ? props.paddings.xs - props.horizontalSpacings.xs / 2 : 8}px;
  > div {
    padding: ${props => props.verticalSpacings ? props.verticalSpacings.xs/2 : 12}px ${props => props.horizontalSpacings ? props.horizontalSpacings.xs/2 : 8}px;
  }
  margin: auto;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: ${props => props.maxWidths.md}px;
    padding: ${props => (props.paddings && props.horizontalSpacings) ? props.paddings.md - props.horizontalSpacings.md / 2 : 8}px;
    > div {
      padding: ${props => props.verticalSpacings ? props.verticalSpacings.md/2 : 12}px ${props => props.horizontalSpacings ? props.horizontalSpacings.md/2 : 8}px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    max-width: ${props => props.maxWidths.lg}px;
    padding: ${props => (props.paddings && props.horizontalSpacings) ? props.paddings.lg - props.horizontalSpacings.lg / 2 : 8}px;
    > div {
      padding: ${props => props.verticalSpacings ? props.verticalSpacings.lg/2 : 12}px ${props => props.horizontalSpacings ? props.horizontalSpacings.lg/2 : 8}px;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}px) {
    max-width: ${props => props.maxWidths.xl}px;
    padding: ${props => (props.paddings && props.horizontalSpacings) ? props.paddings.xl - props.horizontalSpacings.xl / 2 : 8}px;
    > div {
      padding: ${props => props.verticalSpacings ? props.verticalSpacings.xl/2 : 12}px ${props => props.horizontalSpacings ? props.horizontalSpacings.xl/2 : 8}px;
    }
  }
`;

const GridItem = styled.div`
  width: ${props => (props.xs ? props.xs / 12 * 100 : 100)}%;
  flex-grow: 0;
  flex-shrink: 0;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    width: ${props => (props.md ? props.md / 12 * 100 : 100)}%;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: ${props => (props.lg ? props.lg / 12 * 100 : 100)}%;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}px) {
    width: ${props => (props.xl ? props.xl / 12 * 100 : 100)}%;
  }
`;

type Props = {
  maxWidths?: {
    xs?: number,
    md?: number,
    lg?: number,
    xl?: number,
  },
  verticalSpacings?: {
    xs?: number,
    md?: number,
    lg?: number,
    xl?: number,
  },
  horizontalSpacings?: {
    xs?: number,
    md?: number,
    lg?: number,
    xl?: number,
  },
  paddings?: {
    xs?: number,
    md?: number,
    lg?: number,
    xl?: number,
  },
  container?: boolean,
  item?: boolean,
  xs?: number,
  md?: number,
  lg?: number,
  xl?: number,
  children?: React.Node,
  style?: any,
}

export const Grid = ({ maxWidths, container, item, xs, md, lg, xl, children, paddings, verticalSpacings, horizontalSpacings, style }: Props) => {
  if (container) {
    return (
      <GridContainer
        maxWidths={{
          xs: maxWidths && maxWidths.xs,
          md: maxWidths && (maxWidths.md || maxWidths.xs),
          lg: maxWidths && (maxWidths.lg || maxWidths.md || maxWidths.xs),
          xl: maxWidths && (maxWidths.xl || maxWidths.lg || maxWidths.md || maxWidths.xs),
        } || { xs: 375, md: 1148}}
        paddings={{
          xs: paddings && paddings.xs,
          md: paddings && (paddings.md || paddings.xs),
          lg: paddings && (paddings.lg || paddings.md || paddings.xs),
          xl: paddings && (paddings.xl || paddings.lg || paddings.md || paddings.xs),
        }}
        horizontalSpacings={{
          xs: horizontalSpacings && horizontalSpacings.xs,
          md: horizontalSpacings && (horizontalSpacings.md || horizontalSpacings.xs),
          lg: horizontalSpacings && (horizontalSpacings.lg || horizontalSpacings.md || horizontalSpacings.xs),
          xl: horizontalSpacings && (horizontalSpacings.xl || horizontalSpacings.lg || horizontalSpacings.md || horizontalSpacings.xs),
        }}
        verticalSpacings={{
          xs: verticalSpacings && verticalSpacings.xs,
          md: verticalSpacings && (verticalSpacings.md || verticalSpacings.xs),
          lg: verticalSpacings && (verticalSpacings.lg || verticalSpacings.md || verticalSpacings.xs),
          xl: verticalSpacings && (verticalSpacings.xl || verticalSpacings.lg || verticalSpacings.md || verticalSpacings.xs),
        }}
        style={style}
      >
        {children}
      </GridContainer>
    )
  }
  return (
    <GridItem xs={xs} md={md || xs} lg={lg || md || xs} xl={xl || lg || md || xs} style={style}>{children}</GridItem>
  )
}