// @flow
import adhd1 from './adhd-1.png';
import adhd12x from './adhd-1@2x.png';
import adhd13x from './adhd-1@3x.png';
import adhd2 from './adhd-2.png';
import adhd22x from './adhd-2@2x.png';
import adhd23x from './adhd-2@3x.png';
import anger1 from './anger-1.png';
import anger12x from './anger-1@2x.png';
import anger13x from './anger-1@3x.png';
import anger2 from './anger-2.png';
import anger22x from './anger-2@2x.png';
import anger23x from './anger-2@3x.png';
import anger3 from './anger-3.png';
import anger32x from './anger-3@2x.png';
import anger33x from './anger-3@3x.png';
import anxiety1 from './anxiety-1.png';
import anxiety12x from './anxiety-1@2x.png';
import anxiety13x from './anxiety-1@3x.png';
import anxiety2 from './anxiety-2.png';
import anxiety22x from './anxiety-2@2x.png';
import anxiety23x from './anxiety-2@3x.png';
import anxiety3 from './anxiety-3.png';
import anxiety32x from './anxiety-3@2x.png';
import anxiety33x from './anxiety-3@3x.png';
import anxiety4 from './anxiety-4.png';
import anxiety42x from './anxiety-4@2x.png';
import anxiety43x from './anxiety-4@3x.png';
import depression1 from './depression-1.png';
import depression12x from './depression-1@2x.png';
import depression13x from './depression-1@3x.png';
import depression2 from './depression-2.png';
import depression22x from './depression-2@2x.png';
import depression23x from './depression-2@3x.png';
import depression3 from './depression-3.png';
import depression32x from './depression-3@2x.png';
import depression33x from './depression-3@3x.png';
import depression4 from './depression-4.png';
import depression42x from './depression-4@2x.png';
import depression43x from './depression-4@3x.png';
import depression5 from './depression-5.png';
import depression52x from './depression-5@2x.png';
import depression53x from './depression-5@3x.png';
import insomnia1 from './insomnia-1.png';
import insomnia12x from './insomnia-1@2x.png';
import insomnia13x from './insomnia-1@3x.png';
import insomnia2 from './insomnia-2.png';
import insomnia22x from './insomnia-2@2x.png';
import insomnia23x from './insomnia-2@3x.png';
import insomnia3 from './insomnia-3.png';
import insomnia32x from './insomnia-3@2x.png';
import insomnia33x from './insomnia-3@3x.png';
import insomnia4 from './insomnia-4.png';
import insomnia42x from './insomnia-4@2x.png';
import insomnia43x from './insomnia-4@3x.png';
import stress1 from './stress-1.png';
import stress12x from './stress-1@2x.png';
import stress13x from './stress-1@3x.png';
import stress2 from './stress-2.png';
import stress22x from './stress-2@2x.png';
import stress23x from './stress-2@3x.png';
import stress3 from './stress-3.png';
import stress32x from './stress-3@2x.png';
import stress33x from './stress-3@3x.png';
import downloadLg from './downloadLg.png';
import downloadLg2x from './downloadLg@2x.png';
import downloadSm from './downloadSm.png';
import downloadSm2x from './downloadSm@2x.png';
import downloadSm3x from './downloadSm@3x.png';
import loading from './loading.svg';

export default {
  adhd1,
  adhd12x,
  adhd13x,
  adhd2,
  adhd22x,
  adhd23x,
  anger1,
  anger12x,
  anger13x,
  anger2,
  anger22x,
  anger23x,
  anger3,
  anger32x,
  anger33x,
  anxiety1,
  anxiety12x,
  anxiety13x,
  anxiety2,
  anxiety22x,
  anxiety23x,
  anxiety3,
  anxiety32x,
  anxiety33x,
  anxiety4,
  anxiety42x,
  anxiety43x,
  depression1,
  depression12x,
  depression13x,
  depression2,
  depression22x,
  depression23x,
  depression3,
  depression32x,
  depression33x,
  depression4,
  depression42x,
  depression43x,
  depression5,
  depression52x,
  depression53x,
  insomnia1,
  insomnia12x,
  insomnia13x,
  insomnia2,
  insomnia22x,
  insomnia23x,
  insomnia3,
  insomnia32x,
  insomnia33x,
  insomnia4,
  insomnia42x,
  insomnia43x,
  stress1,
  stress12x,
  stress13x,
  stress2,
  stress22x,
  stress23x,
  stress3,
  stress32x,
  stress33x,
  downloadLg,
  downloadLg2x,
  downloadSm,
  downloadSm2x,
  downloadSm3x,
  loading,
}