// @flow
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  max-width: calc(100% - 48px);
  span {
    word-break: keep-all;
  }
`;

export const SurveyNameLabel = styled.span`
  opacity: 0.5;
  font-family: 'GothamRounded';
  font-size: 20px;
  line-height: 1;
  color: #fff;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    font-weight: bold;
    font-size: 32px;
  }
`;

export const CharacterTitle = styled.span`
  font-size: 32px;
  line-height: 1.38;
  text-align: center;
  color: #fff;
  margin-top: 14px;
  > span {
    position: relative;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 8px;
      opacity: ${props => props.surveyKey === 'sleep' ? 0.75 : 0.5};
      background-color: ${props => props.theme.colors[props.surveyKey]};
      z-index: -1;
    }
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    margin-top: 
    font-weight: 300;
    font-size: 64px;
    > span::before {
      height: 16px;
    }
  }
`;

export const ResultPercentLabel = styled.span`
  opacity: 0.5;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 8px;
  color: #fff;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 32px;
  }
`;