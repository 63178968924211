// @flow
import _ from 'lodash';
import * as React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams, Redirect, Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { surveySessionActions } from '../../ducks/survey';
import surveyImages from '../../resources/images/surveyImages';
import { SurveyProgressBar, SurveyQuestion } from '../../components/survey';
import { SURVEY_API_ROOT } from '../../utils/rest';

const mapStateToProps = (state) => {
  return state.survey.session;
}

const Container = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 940px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  margin-top: 24px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: 1048px;
    margin-top: 80px;
  }
`;

const BaseLink = ({ ...props }) => (
  <Link {...props} className={props.className}>{props.children}</Link>
)

const NavButton = styled(BaseLink)`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  opacity: 0.4;
  cursor: pointer;
  min-width: 41px;
  align-items: center;
  height: 14px;
  display: flex;
  > *:not(:first-child) {
    margin-left: 6px;
  }
  > span {
    font-size: 14px;
    line-height: 1;
    color: #fff;
  }
  > img {
    height: 14px;
    width: auto;
    object-fit: contain;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 20px;
    > span {
      font-size: 20px;
    }
    > img {
      height: 20px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  top: 25px;
  width: calc(100% - 180px);
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: 674px;
    width: calc(100% - 185px);
    top: 80px;
  }
`;

const QuestionContainer = styled.div`
  margin-top: 106px;
  width: calc(100% - 48px);
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: 1048px;
    margin-top: 145px;
  }
`;

const saveCurrentSession = (survey, answers) => {
  try {
    const currentSessionJson = JSON.stringify({
      survey,
      answers
    });
    sessionStorage.setItem('currentSession', currentSessionJson);
  } catch (err) {
    console.log(err);
  }
}

const TakeComponent = (props) => {
  const {
    survey,
    answers,
    selectAnswerChoice
  } = props;
  const { surveyName, pageNum } = useParams();
  const history = useHistory();
  const initialShow = React.useRef<Date>(new Date());
  React.useEffect(() => { initialShow.current = new Date() }, []);
  React.useEffect(() => {
    const qIdx = pageNum - 1;
    const currentAnswer = answers[qIdx];
    if (!!currentAnswer && +pageNum === survey.questions.length) {
      //submit answers and go to result page
      axios({
        method: 'POST',
        url: `${SURVEY_API_ROOT}/klar/web/marketing/survey/session`,
        data: {
          answers,
          surveyId: survey.id
        },
      }).then((response) => {
        sessionStorage.removeItem('currentSession');
        history.push(`/result/${response.data.sessionId}`);
      }).catch((error) => {
        console.log(error);
        alert('테스트 답변 제출에 실패했습니다. 새로고침 하여 다시 시도해주세요.');
      })
    }
  }, [answers, pageNum, survey])
  if (!survey) {
    return <Redirect to={`/${surveyName}`}/>;
  }
  if (answers.length < pageNum - 1) {
    return <Redirect to={`/${surveyName}/take/${answers.length + 1}`} />;
  }
  saveCurrentSession(survey, answers);
  const qIdx = pageNum - 1;
  const currentAnswer = answers[qIdx];
  const currentQuestion = survey.questions[qIdx];
  
  return (
    <Container>
      <ProgressContainer>
        <SurveyProgressBar currentQuestion={pageNum} numQuestions={survey.questions.length} />
      </ProgressContainer>
      <NavContainer>
        <NavButton
          onMouseDown={(e) => e.preventDefault()}
          to={pageNum > 1 ? `/${surveyName}/take/${+pageNum - 1}` : `/${surveyName}`}
        >
          <img src={surveyImages.back} alt='back'/>
          <span>이전</span>
        </NavButton>
        {pageNum < survey.questions.length ? (
          <NavButton
            disabled={!currentAnswer}
            onMouseDown={(e) => e.preventDefault()}
            to={`/${surveyName}/take/${+pageNum + 1}`}
          >
            <span>다음</span>
            <img src={surveyImages.forward} alt='forward'/>
          </NavButton>
        ) : null}
      </NavContainer>
      <QuestionContainer>
        <SurveyQuestion surveyName={surveyName} question={currentQuestion} answer={currentAnswer} onAnswerSelected={(choice, score) => {
          const currentTime = new Date();
          selectAnswerChoice(qIdx, choice, score, currentTime - initialShow.current);
          if (pageNum < survey.questions.length) {
            setTimeout(() => history.push(`/${surveyName}/take/${+pageNum + 1}`), 100);
          }
        }}/>
      </QuestionContainer>
    </Container>
  );
}

export const Take = connect(mapStateToProps, {
  selectAnswerChoice: surveySessionActions.selectAnswerChoice
})(TakeComponent);