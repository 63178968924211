// @flow
import adhd1 from './adhd-share-1.png';
import adhd12x from './adhd-share-1@2x.png';
import adhd13x from './adhd-share-1@3x.png';
import adhd2 from './adhd-share-2.png';
import adhd22x from './adhd-share-2@2x.png';
import adhd23x from './adhd-share-2@3x.png';
import anger1 from './anger-share-1.png';
import anger12x from './anger-share-1@2x.png';
import anger13x from './anger-share-1@3x.png';
import anger2 from './anger-share-2.png';
import anger22x from './anger-share-2@2x.png';
import anger23x from './anger-share-2@3x.png';
import anger3 from './anger-share-3.png';
import anger32x from './anger-share-3@2x.png';
import anger33x from './anger-share-3@3x.png';
import anxiety1 from './anxiety-share-1.png';
import anxiety12x from './anxiety-share-1@2x.png';
import anxiety13x from './anxiety-share-1@3x.png';
import anxiety2 from './anxiety-share-2.png';
import anxiety22x from './anxiety-share-2@2x.png';
import anxiety23x from './anxiety-share-2@3x.png';
import anxiety3 from './anxiety-share-3.png';
import anxiety32x from './anxiety-share-3@2x.png';
import anxiety33x from './anxiety-share-3@3x.png';
import anxiety4 from './anxiety-share-4.png';
import anxiety42x from './anxiety-share-4@2x.png';
import anxiety43x from './anxiety-share-4@3x.png';
import depression1 from './depression-share-1.png';
import depression12x from './depression-share-1@2x.png';
import depression13x from './depression-share-1@3x.png';
import depression2 from './depression-share-2.png';
import depression22x from './depression-share-2@2x.png';
import depression23x from './depression-share-2@3x.png';
import depression3 from './depression-share-3.png';
import depression32x from './depression-share-3@2x.png';
import depression33x from './depression-share-3@3x.png';
import depression4 from './depression-share-4.png';
import depression42x from './depression-share-4@2x.png';
import depression43x from './depression-share-4@3x.png';
import depression5 from './depression-share-5.png';
import depression52x from './depression-share-5@2x.png';
import depression53x from './depression-share-5@3x.png';
import insomnia1 from './insomnia-share-1.png';
import insomnia12x from './insomnia-share-1@2x.png';
import insomnia13x from './insomnia-share-1@3x.png';
import insomnia2 from './insomnia-share-2.png';
import insomnia22x from './insomnia-share-2@2x.png';
import insomnia23x from './insomnia-share-2@3x.png';
import insomnia3 from './insomnia-share-3.png';
import insomnia32x from './insomnia-share-3@2x.png';
import insomnia33x from './insomnia-share-3@3x.png';
import insomnia4 from './insomnia-share-4.png';
import insomnia42x from './insomnia-share-4@2x.png';
import insomnia43x from './insomnia-share-4@3x.png';
import stress1 from './stress-share-1.png';
import stress12x from './stress-share-1@2x.png';
import stress13x from './stress-share-1@3x.png';
import stress2 from './stress-share-2.png';
import stress22x from './stress-share-2@2x.png';
import stress23x from './stress-share-2@3x.png';
import stress3 from './stress-share-3.png';
import stress32x from './stress-share-3@2x.png';
import stress33x from './stress-share-3@3x.png';
import surveyList from './open-graph-image-survey-list.png';
import surveyList2x from './open-graph-image-survey-list@2x.png';
import surveyList3x from './open-graph-image-survey-list@3x.png';

export default {
  adhd1,
  adhd12x,
  adhd13x,
  adhd2,
  adhd22x,
  adhd23x,
  anger1,
  anger12x,
  anger13x,
  anger2,
  anger22x,
  anger23x,
  anger3,
  anger32x,
  anger33x,
  anxiety1,
  anxiety12x,
  anxiety13x,
  anxiety2,
  anxiety22x,
  anxiety23x,
  anxiety3,
  anxiety32x,
  anxiety33x,
  anxiety4,
  anxiety42x,
  anxiety43x,
  depression1,
  depression12x,
  depression13x,
  depression2,
  depression22x,
  depression23x,
  depression3,
  depression32x,
  depression33x,
  depression4,
  depression42x,
  depression43x,
  depression5,
  depression52x,
  depression53x,
  insomnia1,
  insomnia12x,
  insomnia13x,
  insomnia2,
  insomnia22x,
  insomnia23x,
  insomnia3,
  insomnia32x,
  insomnia33x,
  insomnia4,
  insomnia42x,
  insomnia43x,
  stress1,
  stress12x,
  stress13x,
  stress2,
  stress22x,
  stress23x,
  stress3,
  stress32x,
  stress33x,
  surveyList,
  surveyList2x,
  surveyList3x,
}