// @flow
import * as React from 'react';
import { ProgressBarContainer, BarTrack, Bar, ProgressLabel } from './styled';

type Props = {
  numQuestions: number,
  currentQuestion: number
};

export const SurveyProgressBar = ({ numQuestions, currentQuestion }: Props) => {
  return (
    <ProgressBarContainer>
      <BarTrack>
        <Bar value={currentQuestion} max={numQuestions} />
      </BarTrack>
      <ProgressLabel>{`${currentQuestion}/${numQuestions}`}</ProgressLabel>
    </ProgressBarContainer>
  )
}