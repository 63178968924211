// @flow
import React from 'react';
import ReactGA from 'react-ga';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { ToastContainer, Slide } from 'react-toastify';
import { BrowserRouter, withRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import * as reducers from './ducks';
import theme from './resources/theme';
import { Footer } from './components/common';
import { Screens } from './screens';

const Container = styled.div`
  height: 100%;
`;

class ScrollToTopComponent extends React.Component<any> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTop = withRouter(ScrollToTopComponent);

const loadCurrentSession = () => {
  try {
    const currentSessionJson = sessionStorage.getItem('currentSession');
    if (currentSessionJson === null || currentSessionJson === undefined) {
      return {
        survey: null,
        answers: []
      };
    }
    const currentSession = JSON.parse(currentSessionJson);
    return currentSession;
  } catch (err) {
    return {
      survey: null,
      answers: []
    };
  }
};

function App({ userAgent }) {
  console.log(userAgent)
  const store = createStore(combineReducers(reducers), {
    survey: {
      session: loadCurrentSession()
    }
  });
  React.useEffect(() => {
    ReactGA.initialize('UA-155724861-2')
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ScrollToTop>
          <Container>
            <Screens userAgent={userAgent || (navigator && navigator.userAgent)}/>
            <Footer/>
            <ToastContainer hideProgressBar transition={Slide}/>
          </Container>
        </ScrollToTop>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
