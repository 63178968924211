// @flow
import _ from 'lodash';
import * as React from 'react';
import { Grid } from '../../common';
import { CharacterImg, DescriptionLabel } from './styled';
import resultImages from '../../../resources/images/resultImages';

type Props = {
  anlzResult: any
};

export const ResultBody = ({ anlzResult }: Props) => {
  const { anlzResult: innerResult } = anlzResult;
  const level = innerResult && innerResult.actualLevel.value;
  const key = _.lowerCase(anlzResult.pubSurveyName);
  const charDesc = innerResult && _.filter(innerResult.additions, (addition) => addition.key === 'character.description')[0].value;
  const commentTitle = innerResult && _.filter(innerResult.additions, (addition) => addition.key === 'comment.title')[0].value;
  const commentDesc = innerResult && _.filter(innerResult.additions, (addition) => addition.key === 'comment.description')[0].value;
  const recommendationTitle = innerResult && _.filter(innerResult.additions, (addition) => addition.key === 'recommendation.title')[0].value;
  const recommendationDesc = innerResult && _.filter(innerResult.additions, (addition) => addition.key === 'recommendation.description')[0].value;
  return (
    <Grid
      container
      maxWidths={{ lg: 1144 }}
      paddings={{ xs: 24, lg: 80 }}
      verticalSpacings={{ xs: 32, lg: 120 }}
      horizontalSpacings={{ xs: 32, lg: 120 }}
    >
      <Grid item xs={12} lg={6} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CharacterImg
          src={resultImages[(!!key && !!level) ? `${key}${level}` : 'loading']}
          srcSet={(!!key && !!level) ? `${resultImages[`${key}${level}2x`]} 2x, ${resultImages[`${key}${level}3x`]} 3x` : null}
          alt='result character'
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DescriptionLabel>
          {charDesc}<br/>
          <br/>
          <strong>{commentTitle}</strong><br/>
          {commentDesc}<br/>
          <br/>
          <strong>{recommendationTitle}</strong><br/>
          {recommendationDesc}
        </DescriptionLabel>
      </Grid>
    </Grid>
  )
}