// @flow
import _ from 'lodash';
import * as React from 'react';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import {
  FooterContainer,
  ShareResultHeading,
  ShareResultHeadingContainer,
  ShareResultDivider,
  ShareResultButtonContainer,
  ShareButton,
  LinkAddress,
  OtherTestsButton
} from './styled';
import surveyImages from '../../../resources/images/surveyImages';
import { useRemoteLoadableState } from '../../../utils/hooks';
import { SURVEY_API_ROOT } from '../../../utils/rest';
import { useLocation, useHistory } from 'react-router-dom';
import shareImages from '../../../resources/images/shareImages';

type Props = {
  anlzResult: any,
  level: number,
  surveyLocalModel: any,
  resultToShare: string,
  percent: string,
}

export const ResultFooter = ({ surveyLocalModel, level, anlzResult, resultToShare, percent }: Props) => {
  const linkAddressEl = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const surveyName = _.lowerCase(anlzResult.pubSurveyName);
  const [survey, loading, refetchSurvey] = useRemoteLoadableState(
    `${SURVEY_API_ROOT}/klar/web/marketing/survey/pub-survey`,
    { name: _.toUpper(surveyName) },
    true,
    {}
  );
  return (
    <FooterContainer>
      <ShareResultHeadingContainer>
        <ShareResultDivider/>
        <ShareResultHeading hidden='xs'>#클라 와 함께 결과를 공유해보세요!</ShareResultHeading>
        <ShareResultHeading hidden='lg'>#클라 와 함께 결과를 공유해보세요!</ShareResultHeading>
        <ShareResultDivider/>
      </ShareResultHeadingContainer>
      <ShareResultButtonContainer>
        <ShareButton onClick={(e) => {
          if (linkAddressEl.current) {
            linkAddressEl.current.select()
            linkAddressEl.current.setSelectionRange(0, 99999);
            document.execCommand('copy');
            toast('링크를 클립보드에 복사했습니다.', {
              autoClose: 3000,
              position: toast.POSITION.BOTTOM_CENTER
            });
            ReactGA.event({
              category: 'Social',
              action: `Share ${surveyName} Result`,
              label: 'Share via Link'
            });
          };
        }}>
          <img
            src={surveyImages.link}
            srcSet={`${surveyImages.link2x} 2x, ${surveyImages.link3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          if (linkAddressEl.current) {
            ReactGA.event({
              category: 'Social',
              action: `Share ${surveyName} Result`,
              label: 'Share via Kakao'
            });
            global.Kakao.Link.sendDefault({
              objectType: 'feed',
              content: {
                title: surveyLocalModel && surveyLocalModel.resultTitles[level - 1],
                description: `내 ${surveyLocalModel && surveyLocalModel.assessing}는 ${percent}%입니다.`,
                imageUrl: shareImages[_.join(_.split(resultToShare, '-'), '')],
                link: {
                  mobileWebUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`,
                  webUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`,
                },
              },
              buttons: [
                {
                  title: '웹으로 이동',
                  link: {
                    mobileWebUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`,
                    webUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`,
                  },
                },
              ],
              success: function(response) {
                console.log(response);
              },
              fail: function(error) {
                console.log(error);
              }
            });
          }
        }}>
          <img
            src={surveyImages.kakao}
            srcSet={`${surveyImages.kakao2x} 2x, ${surveyImages.kakao3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          ReactGA.event({
            category: 'Social',
            action: `Share ${surveyName} Result`,
            label: 'Share via Instagram'
          });
          window.open('https://www.instagram.com/');
        }}>
          <img
            src={surveyImages.instagram}
            srcSet={`${surveyImages.instagram2x} 2x, ${surveyImages.instagram3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          ReactGA.event({
            category: 'Social',
            action: `Share ${surveyName} Result`,
            label: 'Share via Facebook'
          });
          global.FB.ui({
            method: 'share',
            href: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`
          }, function(response){});
        }}>
          <img
            src={surveyImages.facebook}
            srcSet={`${surveyImages.facebook2x} 2x, ${surveyImages.facebook3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
      </ShareResultButtonContainer>
      <LinkAddress type='text' ref={linkAddressEl} value={`${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}?sharedResult=${resultToShare}&percent=${percent}`} readOnly />
      <OtherTestsButton
        onClick={(e) => {
          history.push('/');
        }}
      >
        <span>{'다른 테스트 해보기'}</span>
        <img src={surveyImages.forward} alt='forward'/>
      </OtherTestsButton>
    </FooterContainer>
  )
}