// @flow
import * as React from 'react';
import {
  Container,
  SNSLinksContainer,
  SNSLink,
  EULAPrivacyContainer,
  PolicyLink,
  CompanyInfo,
  CompanyInfoPiece,
  CompanyInfoDivider,
  Copyright
} from './styled';
import commonImages from '../../../resources/images/commonImages';


const SNSLinks = () => {
  return (
    <SNSLinksContainer>
      <SNSLink target='_blank' href='https://www.instagram.com/klar._.official/' rel='noopener noreferrer'>
        <img src={commonImages.instagram} alt='instagram'/>
      </SNSLink>
      <SNSLink target='_blank' href='https://www.youtube.com/channel/UCQai3tCnjyjxkSZMkqmu1TQ' rel='noopener noreferrer'>
        <img src={commonImages.youtube} alt='youtube'/>
      </SNSLink>
      <SNSLink target='_blank' href='https://post.naver.com/klar_official' rel='noopener noreferrer'>
        <img src={commonImages.naver} alt='naver'/>
      </SNSLink>
      <SNSLink target='_blank' href='https://www.facebook.com/KLAR-106993874156823/' rel='noopener noreferrer'>
        <img src={commonImages.facebook} alt='facebook'/>
      </SNSLink>
    </SNSLinksContainer>
  )
}

export const Footer = () => {
  return (
    <Container>
      <SNSLinks/>
      <EULAPrivacyContainer>
        <PolicyLink href='https://www.everydayklar.com/terms'>
          서비스 이용약관
        </PolicyLink>
        <PolicyLink href='https://www.everydayklar.com/privacy'>
          개인정보 처리방침
        </PolicyLink>
      </EULAPrivacyContainer>
      <CompanyInfo top>
        <CompanyInfoPiece>(주) 와이브레인</CompanyInfoPiece>
        <CompanyInfoDivider/>
        <CompanyInfoPiece>사업자등록번호: 314-86-43580</CompanyInfoPiece>
        <CompanyInfoDivider/>
        <CompanyInfoPiece>대표: 이기원</CompanyInfoPiece>
      </CompanyInfo>
      <CompanyInfo>
        <CompanyInfoPiece style={{ marginRight: '24px' }}>경기도 성남시 수정구 창업로 54 228호</CompanyInfoPiece>
        <CompanyInfoPiece>이메일 : klar_official@ybrain.com</CompanyInfoPiece>
      </CompanyInfo>
      <CompanyInfo>
        <Copyright>
        © 2020 Ybrain, Inc, All Rights Reserved.
        <br/>
        Illustrations are derivatives of "<a href="https://www.humaaans.com/" target="_blank">humaaans</a>" by <a href="https://twitter.com/pablostanley" target="_blank">Pablo Stanley</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>. All illustrations are licensed by Ybrain Inc.
        </Copyright>
      </CompanyInfo>
    </Container>
  );
}