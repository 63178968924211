// @flow
import styled from 'styled-components';

export const CharacterImg = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  border: none;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    width: 432px;
    height: 432px;
  }
`;

export const DescriptionLabel = styled.p`
  margin: 0;
  padding: 0;
  opacity: 0.75;
  word-break: keep-all;
  text-align: center;
  font-size: 16px;
  line-height: 2;
  color: #fff;
  @media only screen and (min-width: ${props => props.theme.breakpoints.lg}px) {
    font-size: 20px;
    opacity: 1;
    text-align: left;
  }
`;