// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  QuestionContainer,
  CommentLabel,
  PromptLabel,
  AnswerChoiceContainer,
  AnswerRangeLabel,
  ChoiceButtonContainer,
  ChoiceButton
} from './styled';

type Props = {
  surveyName: string,
  question: any,
  answer: any,
  onAnswerSelected: (choice: Number, score: number) => any
};

export const SurveyQuestion = ({ surveyName, question, onAnswerSelected, answer }: Props) => {
  const choiceValues = _.range(
    question.leftValue, 
    (question.rightValue > question.leftValue) ? question.rightValue + question.incrUnit : question.rightValue - question.incrUnit, 
    (question.rightValue > question.leftValue) ? question.incrUnit : question.incrUnit * -1
  );
  return (
    <QuestionContainer>
      <CommentLabel dangerouslySetInnerHTML={{ __html: question.comment }}/>
      <PromptLabel>{question.content}</PromptLabel>
      <AnswerChoiceContainer>
        <AnswerRangeLabel>{question.leftText}</AnswerRangeLabel>
        <ChoiceButtonContainer>
          {_.map(choiceValues, (value, index) => (
            <ChoiceButton
              key={`choice_${index}`}
              selected={!answer ? false : answer.score === value}
              surveyKey={surveyName}
              onClick={(e) => {
                onAnswerSelected(index, value);
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
          ))}
        </ChoiceButtonContainer>
        <AnswerRangeLabel>{question.rightText}</AnswerRangeLabel>
      </AnswerChoiceContainer>
    </QuestionContainer>
  );
}