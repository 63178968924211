// @flow
import adhdBg from './card-bg-adhd.svg';
import angerBg from './card-bg-anger.svg';
import anxietyBg from './card-bg-anxiety.svg';
import depressionBg from './card-bg-depression.svg';
import insomniaBg from './card-bg-insomnia.svg';
import stressBg from './card-bg-stress.svg';

export default {
  adhdBg,
  angerBg,
  anxietyBg,
  depressionBg,
  insomniaBg,
  stressBg,
}