import facebook from './facebook.svg';
import instagram from './instagram.svg';
import naver from './naver.svg';
import youtube from './youtube.svg';
import mainBg from './bg.svg';
import mainBg1x from './bg.png';
import mainBg2x from './bg@2x.png';
import mainBg3x from './bg@3x.png';
import logo from './logo-xs.svg';
import logoMD from './logo-md.svg';
import logoLG from './logo-lg.svg';
import appStore from './app-store-button.png';
import appStore2x from './app-store-button@2x.png';
import appStore3x from './app-store-button@3x.png';
import googlePlay from './google-play-button.png';
import googlePlay2x from './google-play-button@2x.png';
import googlePlay3x from './google-play-button@3x.png';

export default {
  facebook,
  instagram,
  naver,
  youtube,
  mainBg,
  mainBg1x,
  mainBg2x,
  mainBg3x,
  logo,
  logoMD,
  logoLG,
  appStore,
  appStore2x,
  appStore3x,
  googlePlay,
  googlePlay2x,
  googlePlay3x,
}