// @flow
import * as React from 'react';
import styled from 'styled-components';
import commonImages from '../../resources/images/commonImages';
import { SurveyList } from '../../components/home';
import shareImages from '../../resources/images/shareImages';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  width: 100%;
  padding-bottom: 90px;
`;

const HomeHeadingContainer = styled.div`
  width: 100%;
  padding: 40px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

const HomeSurveyHeading = styled.span`
  font-family: 'GothamRounded';
  font-size: 20px;
  opacity: 0.5;
  line-height: 1;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 32px;
    font-weight: bold;
  }
`;

const HomePrompt = styled.span`
  padding: 0;
  margin-top: 16px;
  font-size: 32px;
  max-width: 258px;
  line-height: 1.25;
  white-space: pre-line;
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 48px;
    max-width: ${props => props.theme.breakpoints.md}px;
  }
`;

const HomeSubPrompt = styled.span`
  padding: 0;
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.5;
  line-height: 1.5;
  white-space: pre-line;
  text-align: center;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const Home = () => {
  return (
    <Container>
      <Helmet>
        <meta property="og:url" content={`${process.env.PUBLIC_URL || 'http://localhost:3000'}`}/>
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="2434875116760316" />
        <meta property="og:title" content='최근 내 멘탈상태를 확인해보세요.'/>
        <meta property="og:description" content='간단한 자가진단 설문을 통해 최근 내 멘탈상태를 확인해보세요.'/>
        <meta property="og:image" content={shareImages.surveyList}/>
        <meta property="og:image:width" content='1200'/>
        <meta property="og:image:height" content='630'/>
      </Helmet>
      <HomeHeadingContainer>
        <HomeSurveyHeading>SELF-CHECK</HomeSurveyHeading>
        <HomePrompt>{'최근 내 멘탈상태를 확인해보세요.'}</HomePrompt>
        <HomeSubPrompt>{'간단한 자가진단 설문을 통해\n최근 내 멘탈상태를 확인해보세요.'}</HomeSubPrompt>
      </HomeHeadingContainer>
      <SurveyList/>
    </Container>
  )
}
