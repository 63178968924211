// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  HeaderContainer,
  SurveyNameLabel,
  CharacterTitle,
  ResultPercentLabel
} from './styled';
import surveys from '../../../resources/surveys';

type Props = {
  anlzResult: any
};

export const ResultHeader = ({ anlzResult }: Props) => {
  const key = _.lowerCase(anlzResult.pubSurveyName);
  const surveyLocalModel = _.filter(surveys, (model) => model.key === key)[0];
  const characterTitle = _.filter(anlzResult.anlzResult && anlzResult.anlzResult.additions, (addition) => addition.key === 'character.title')[0];
  const resultCharacter = surveyLocalModel && anlzResult.anlzResult && surveyLocalModel.resultCharacters[anlzResult.anlzResult.actualLevel.value - 1];
  const characterTitlePrefix = characterTitle && _.split(characterTitle.value, resultCharacter)[0];
  return (
    <HeaderContainer>
      <SurveyNameLabel>{anlzResult && anlzResult.pubSurveyName}</SurveyNameLabel>
      <CharacterTitle surveyKey={key}>{characterTitlePrefix}<span>{resultCharacter}</span></CharacterTitle>
      <ResultPercentLabel>{`내 ${surveyLocalModel && surveyLocalModel.assessing}는 `}<strong>{`${anlzResult.anlzResult && anlzResult.anlzResult.percent}%`}</strong>{`입니다.`}</ResultPercentLabel>
    </HeaderContainer>
  )
}