// @flow
import _ from 'lodash';
import * as React from 'react';
import ReactGA from 'react-ga';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BodyContainer,
  BodyContent,
  ShareSurveyHeadingContainer,
  ShareSurveyDivider,
  ShareSurveyHeading,
  ShareSurveyButtonContainer,
  ShareButton,
  LinkAddress,
  OtherTestsButton,
} from './styled';
import surveyImages from '../../../resources/images/surveyImages';
import surveys from '../../../resources/surveys';

type Props = {
  survey: any
}

export const SurveyIntroBody = ({ survey }: Props) => {
  const linkAddressEl = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { surveyName } = useParams();
  return (
    <BodyContainer>
      <BodyContent dangerouslySetInnerHTML={{ __html: survey.explanation }}></BodyContent>
      <ShareSurveyHeadingContainer>
        <ShareSurveyDivider/>
        <ShareSurveyHeading>테스트 공유하기</ShareSurveyHeading>
        <ShareSurveyDivider/>
      </ShareSurveyHeadingContainer>
      <ShareSurveyButtonContainer>
        <ShareButton onClick={(e) => {
          console.log(linkAddressEl);
          if (linkAddressEl.current) {
            linkAddressEl.current.select()
            linkAddressEl.current.setSelectionRange(0, 99999);
            document.execCommand('copy');
            toast('링크를 클립보드에 복사했습니다.', {
              autoClose: 3000,
              position: toast.POSITION.BOTTOM_CENTER
            });
            ReactGA.event({
              category: 'Social',
              action: `Share ${surveyName} Intro`,
              label: 'Share via Link'
            });
          };
        }}>
          <img
            src={surveyImages.link}
            srcSet={`${surveyImages.link2x} 2x, ${surveyImages.link3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          if (linkAddressEl.current) {
            ReactGA.event({
              category: 'Social',
              action: `Share ${surveyName} Intro`,
              label: 'Share via Kakao'
            });
            global.Kakao.Link.sendDefault({
              objectType: 'feed',
              content: {
                title: survey.description.split('\n')[0],
                description: survey.description.split('\n')[1],
                imageUrl: surveyImages[`${surveyName}Header`],
                link: {
                  mobileWebUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`,
                  webUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`,
                },
              },
              buttons: [
                {
                  title: '웹으로 이동',
                  link: {
                    mobileWebUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`,
                    webUrl: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`,
                  },
                },
              ],
              success: function(response) {
                console.log(response);
              },
              fail: function(error) {
                console.log(error);
              }
            });
          }
        }}>
          <img
            src={surveyImages.kakao}
            srcSet={`${surveyImages.kakao2x} 2x, ${surveyImages.kakao3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          ReactGA.event({
            category: 'Social',
            action: `Share ${surveyName} Intro`,
            label: 'Share via Instagram'
          });
          window.open('https://www.instagram.com/');
        }}>
          <img
            src={surveyImages.instagram}
            srcSet={`${surveyImages.instagram2x} 2x, ${surveyImages.instagram3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
        <ShareButton onClick={(e) => {
          ReactGA.event({
            category: 'Social',
            action: `Share ${surveyName} Intro`,
            label: 'Share via Facebook'
          });
          global.FB.ui({
            method: 'share',
            href: `${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`
          }, function(response){});
        }}>
          <img
            src={surveyImages.facebook}
            srcSet={`${surveyImages.facebook2x} 2x, ${surveyImages.facebook3x} 3x`}
            alt='Link Share'
          />
        </ShareButton>
      </ShareSurveyButtonContainer>
      <OtherTestsButton
        onClick={(e) => {
          history.push('/');
        }}
      >
        <span>{'다른 테스트 해보기'}</span>
        <img src={surveyImages.forward} alt='forward'/>
      </OtherTestsButton>
      <LinkAddress type='text' ref={linkAddressEl} value={`${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`} readOnly />
    </BodyContainer>
  )
};