// @flow
export default {
  breakpoints: {
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  colors: {
    depression: 'rgb(0,161,185)',
    stress: 'rgb(171,11,100)',
    insomnia: 'rgb(84,27,134)',
    anxiety: 'rgb(249,198,35)',
    anger: 'rgb(223,72,120)',
    adhd: 'rgb(227,103,43)',
    mediumGrey1: '#797885',
    mediumGrey2: '#848390',
    lightGrey: '#c5c5c9',
    klarDeep: '#131034',
  },
}