// @flow
import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  > *:not(:first-child) {
    margin-left: 8px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 20px;
    > *:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export const BarTrack = styled.div`
  flex-grow: 1;
  height: 8px;
  background-color: rgba(255,255,255,0.1);
  position: relative;
  border-radius: 16px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 12px;
  }
`;

export const Bar = styled.div`
  height: 100%;
  width: ${props => (props.value / props.max) * 100}%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.25;
  border-radius: 16px;
`;

export const ProgressLabel = styled.span`
  font-family: 'GothamRounded';
  font-size: 14px;
  line-height: 1;
  color: #fff;
  opacity: 0.4;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 20px;
  }
`;