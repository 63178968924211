// @flow
export default [{
  key: 'stress',
  heading: 'STRESS',
  title: '스트레스',
  assessing: '스트레스 지수',
  subtitle: '나는 어떤 유형의 과일일까요?',
  resultCharacters: ['수박', '바나나', '토마토'],
  resultTitles: ['껍질 단단한 수박', '여기저기 멍든 바나나', '물러서 잘 터지는 토마토'],
  citation: '출처: 이종하, 신철민, 고영훈, 임재형, 조숙행, 김승현, 정인과, 한창수(2012). 한글판 스트레스 자각척도의 신뢰도와 타당도 연구. 정신신체의학, 20(2), 127-134.',
}, {
  key: 'insomnia',
  heading: 'INSOMNIA',
  title: '불면 지수',
  assessing: '불면 지수',
  subtitle: '당신의 꿀잠 라이프, 안녕하신가요?',
  resultCharacters: ['코알라', '고슴도치', '돌고래', '기린'],
  resultTitles: ['세상에서 제일 잘 자는 코알라', '귀가 밝아 자주 깨는 고슴도치', '한 눈을 뜨고 자는 돌고래', '하루 2시간 자는 기린'],
  citation: '출처: Yong Won Cho, Mei Ling Song, Charies M. Morin(2014). Validation of a Korean Version of the Insomnia Severity Index. J Clin Neurol 10(3), 210-215.',
}, {
  key: 'depression',
  heading: 'DEPRESSION',
  title: '우울감',
  assessing: '우울 지수',
  subtitle: '내가 나에게 꽃을 선물한다면?',
  resultCharacters: ['벚꽃', '크리스마스로즈', '캐모마일', '붉은 동백', '메리골드'],
  resultTitles: ['고운 마음, 벚꽃', '내 마음을 달래주세요, 크리스마스로즈', '괜찮아질 수 있어, 캐모마일', '누구보다 그대가 좋아, 붉은 동백', '반드시 찾아올 행복, 메리골드'],
  citation: '출처: 박승진, 최혜라, 최지혜, 김건우, 홍진표(2010). 한글판 우울증선별도구(Patient Health Questionnaire-9, PHQ-9)의 신뢰도와 타당도. 대한불안의학회지 6, 119-24.',
}, {
  key: 'anxiety',
  heading: 'ANXIETY',
  title: '불안감',
  assessing: '불안 지수',
  subtitle: '내 마음 속에 어떤 강아지가 살고 있을까요?',
  resultCharacters: ['차우차우', '푸들', '치와와', '퍼그'],
  resultTitles: ['여유를 즐기는 차우차우', '가끔 예민한 푸들', '자주 긴장하는 치와와', '호흡이 어려운 퍼그'],
  citation: '출처: Spitzer RL, Kroenke K, Williams JB, et al.(2006). A brief measure for assessing generalized anxiety disorder: the GAD-7, Arch Intern Med 166, 1092-7',
}, {
  key: 'anger',
  heading: 'ANGER',
  title: '분노 지수',
  assessing: '분노 지수',
  subtitle: '나는 얼마나 불같은 사람일까요?',
  resultCharacters: ['스윗칠리', '청양고추', '마라소스'],
  resultTitles: ['순한 맛, 스윗칠리', '매운 맛, 청양고추', '불타는 맛, 마라소스'],
  citation: '출처: 삼성서울병원',
}, {
  key: 'adhd',
  heading: 'ATTENTION DEFICIT',
  title: '성인 ADHD',
  assessing: 'ADHD 지수',
  subtitle: '이 구역의 산만 보스, 혹시 나도 성인 ADHD?',
  resultCharacters: ['브라우니', 'M&M'],
  resultTitles: ['브라운 ONLY 브라우니', '알록달록 무지개색, M&M'],
  citation: '출처: Kessler, Ronald C.; Adler, Lenard A.; Gruber, Michael J.; Sarawate, Chaitanya A.; Spencer, Thomas; Van Brunt, David L. (June 2007). "Validity of the World Health Organization Adult ADHD Self-Report Scale (ASRS) Screener in a representative sample of health plan members". International Journal of Methods in Psychiatric Research. 16 (2): 52–65.',
}];