// @flow
import _ from 'lodash';
import * as React from 'react';
import {
  HeaderContainer,
  SurveyName,
  SurveyDescMain,
  SurveyDescSub,
  StartButton
} from './styled';
import surveys from '../../../resources/surveys';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { surveySessionActions } from '../../../ducks/survey';

type Props = {
  survey: any
}

const SurveyIntroHeaderComponent = (props) => {
  const {
    survey,
    startSurvey
  } = props;
  const surveyDescLines = _.split(survey.description, '\n');
  const surveyLocalModel = _.filter(surveys, (model) => _.toUpper(model.key) === survey.name)[0];
  const surveyMainDescs = _.split(surveyDescLines[0], (surveyLocalModel && surveyLocalModel.assessing) || ' ');
  const surveyMainDescPrefix = surveyMainDescs[0];
  const surveyMainDescSuffix = surveyMainDescs[1];
  return (
    <HeaderContainer>
      <SurveyName>{survey.name}</SurveyName>
      <SurveyDescMain surveyKey={surveyLocalModel && surveyLocalModel.key}>{surveyMainDescPrefix}<span>{surveyLocalModel && surveyLocalModel.assessing}</span>{surveyMainDescSuffix}</SurveyDescMain>
      <SurveyDescSub>{surveyDescLines[1]}</SurveyDescSub>
      <StartButton
        onClick={(e) => {
          console.log('start survey');
          startSurvey(survey);
        }}
        to={`/${surveyLocalModel && surveyLocalModel.key}/take`}
        surveyKey={surveyLocalModel && surveyLocalModel.key}
      >
        시작하기
      </StartButton>
    </HeaderContainer>
  );
};

export const SurveyIntroHeader = connect(null, {
  startSurvey: surveySessionActions.startSurvey
})(SurveyIntroHeaderComponent);
