// @flow
import adhdHeader from './adhd-header.png';
import adhdHeader2x from './adhd-header@2x.png';
import angerHeader from './anger-header.png';
import angerHeader2x from './anger-header@2x.png';
import anxietyHeader from './anxiety-header.png';
import anxietyHeader2x from './anxiety-header@2x.png';
import depressionHeader from './depression-header.png';
import depressionHeader2x from './depression-header@2x.png';
import insomniaHeader from './insomnia-header.png';
import insomniaHeader2x from './insomnia-header@2x.png';
import stressHeader from './stress-header.png';
import stressHeader2x from './stress-header@2x.png';
import kakao from './kakao.png';
import kakao2x from './kakao@2x.png';
import kakao3x from './kakao@3x.png';
import facebook from './facebook.png';
import facebook2x from './facebook@2x.png';
import facebook3x from './facebook@3x.png';
import instagram from './instagram.png';
import instagram2x from './instagram@2x.png';
import instagram3x from './instagram@3x.png';
import link from './link.png';
import link2x from './link@2x.png';
import link3x from './link@3x.png';
import back from './back.svg';
import forward from './forward.svg';

export default {
  adhdHeader,
  adhdHeader2x,
  angerHeader,
  angerHeader2x,
  anxietyHeader,
  anxietyHeader2x,
  depressionHeader,
  depressionHeader2x,
  insomniaHeader,
  insomniaHeader2x,
  stressHeader,
  stressHeader2x,
  kakao,
  kakao2x,
  kakao3x,
  facebook,
  facebook2x,
  facebook3x,
  instagram,
  instagram2x,
  instagram3x,
  link,
  link2x,
  link3x,
  back,
  forward,
}