// @flow
import _ from 'lodash';
import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useRemoteLoadableState } from '../../utils/hooks';
import { SURVEY_API_ROOT } from '../../utils/rest';
import { SurveyIntroHeader, SurveyIntroBody } from '../../components/survey';
import surveyImages from '../../resources/images/surveyImages';
import surveys from '../../resources/surveys';

const Container = styled.div`
  width: 100%;
`;

const BackgroundPlaceholder = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 352px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  background-image: url(${props => surveyImages[`${props.surveyName}Header`]});
  background-position: top center;
  background-size: cover;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 543px;
  }
`;

const HeaderImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(19, 16, 52, 0.2) 19%, rgba(19, 16, 52, 0.8) 60%);
`;

export const Intro = () => {
  const { surveyName } = useParams();
  const surveyLocalModel = _.filter(surveys, (model) => model.key === surveyName)[0];
  const location = useLocation();
  const [surveyModel, loading, refetchSurvey] = useRemoteLoadableState(
    `${SURVEY_API_ROOT}/klar/web/marketing/survey/pub-survey`,
    { name: _.toUpper(surveyName) },
    true,
    {}
  );
  return (
    <Container>
      <Helmet>
        <meta property="og:url" content={`${process.env.PUBLIC_URL || 'http://localhost:3000'}${location.pathname}`}/>
        <meta property="og:type" content="article" />
        <meta property="fb:app_id" content="2434875116760316" />
        <meta property="og:title" content={`지금 내 ${surveyLocalModel.assessing}를 테스트해보세요!`}/>
        <meta property="og:description" content={surveyLocalModel.subtitle}/>
        <meta property="og:image" content={surveyImages[`${surveyName}Header`]}/>
        <meta property="og:image:width" content='1440'/>
        <meta property="og:image:height" content='543'/>
      </Helmet>
      <BackgroundPlaceholder surveyName={surveyName}>
        <HeaderImageOverlay/>
      </BackgroundPlaceholder>
      <SurveyIntroHeader survey={surveyModel}/>
      <SurveyIntroBody survey={surveyModel}/>
    </Container>
  );
}