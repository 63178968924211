// @flow
import styled from 'styled-components';

export const SurveyCardContainer = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const SurveyCardImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export const SurveyHeadingContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  max-width: calc(100%-20px);
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    top: 30px;
    left: 25px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}px) {
    top: 40px;
    left: 40px;
  }
`;

export const SurveyHeading = styled.span`
  display: block;
  color: #fff;
  opacity: 0.5;
  line-height: 0.6;
  font-family: "GothamRounded";
  font-size: 10px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 16px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}px) {
    font-size: 20px;
  }
`;

export const SurveyTitle = styled.span`
  display: block;
  color: #fff;
  font-weight: bold;
  margin-top: 4px;
  line-height: 1.0;
  font-size: 20px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 6px;
    font-size: 32px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}px) {
    margin-top: 8px;
    font-size: 40px;
  }
`;