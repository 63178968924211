// @flow
import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 24px;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    padding: 32px 64px 64px 72px;
  }
`;

export const SNSLinksContainer = styled.div`
  display: flex;
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    position: absolute;
    top: 56px;
    right: 64px;
  }
`;

export const SNSLink = styled.a`
  &:not(:first-child) {
    margin-left: 12px;
  }
  flex-grow: 0;
  width: 32px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    &:not(:first-child) {
      margin-left: 20px;
    }
    width: 48px;
    height: 48px;
  }
`;

export const EULAPrivacyContainer = styled.div`
  margin-top: 32px;
`;

export const PolicyLink = styled.a`
  font-weight: bold;
  font-size: 12px;
  color: ${props => props.theme.colors.mediumGrey2};
  &:not(:first-child) {
    margin-left: 47px;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const CompanyInfo = styled.div`
  margin-top: ${props => props.top ? 24 : 16}px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  > * {
    margin: 4px 12px 4px 0;
  }
  @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: ${props => props.top ? 24 : 8}px;
  }
`;

export const CompanyInfoPiece = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.mediumGrey1};
`

export const CompanyInfoDivider = styled.div`
  width: 1px;
  height: 12px;
  flex: 0 0 1px;
  background-color: ${props => props.theme.colors.lightGrey};
  display: inline-block;
`;

export const Copyright = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colors.mediumGrey1};
  > a {
    color: ${props => props.theme.colors.mediumGrey2};
    font-weight: bold;
    text-decoration: underline;
  }
`;